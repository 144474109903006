import React, { useState } from 'react';
import {
    Box,
    Text,
    VStack,
    Switch,
    HStack,
    Spacer,
    Link
} from "@chakra-ui/react"
import { ExternalLinkIcon } from '@chakra-ui/icons'

const Blocklist = (arg) => {
    const [isSelected, setSelected] = useState(false);

    const toggle = (event) => {
        setSelected(!isSelected);
        arg.onSelect(arg.data.id, !isSelected);
        event.stopPropagation();
    }

    return (
        <Box maxW="100vw" minW="95%" minH="100px" borderWidth="1px" borderRadius="lg" overflow="hidden" marginBottom="10px" >
            <HStack spacing={1} align="center" margin="10px">
                <VStack spacing={1} align="left" onClick={toggle}>
                    <Text fontSize="2xl" align="left">{arg.data.name}</Text>
                    <HStack>
                        <Link href={ arg.data.url } isExternal colorScheme="yellow" align="left" >
                            view <ExternalLinkIcon mx="2px" />
                        </Link>
                        <Text align="left">| {arg.data.domains} domains</Text>
                    </HStack>
                </VStack>
                <Spacer onClick={toggle} />
                <VStack align="right">
                    <Switch
                    isChecked={isSelected}
                    onChange={toggle}
                    size="lg"
                    colorScheme="green"
                    align="right"
                    />
                    <Box
                        borderWidth="1px"
                        borderRadius="lg"
                        margin="1px"
                        onClick={toggle}
                    >
                        <Text margin="5px">{arg.data.description}</Text>
                    </Box>
                    
                </VStack>
                
            </HStack>
        </Box>
    )
}

export default Blocklist;