import React, { useState } from 'react';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
    Button,
    IconButton,
    Flex
} from "@chakra-ui/react";
import { ChevronDownIcon } from '@chakra-ui/icons';
import axios from 'axios';

const apiUrl = "https://blitz.ahadns.com/ping";
let lowest = 0;

const LatencyMonitor = (arg) => {
    const [latency, setLatency] = useState(0);
    const [server, setServer] = useState("unknown");
    const [isSelected, setSelected] = useState(false);

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    const measureLatency = async () => {
        try {
            let start = Date.now();
            let response = await axios.get(apiUrl);
            let end = Date.now();
            let measuredLatency = Math.floor((end - start) / 2.0);
            setLow(measuredLatency);
            setLatency(lowest);
            setServer(`${response.data.city}, ${response.data.countryCode}`);

        }
        catch (e) {
            console.error(e)
        }
    }

    const loop = async () => {
        for (let i = 0; i < 50; i++) {
            await measureLatency();
            await sleep(500);
        }
    }

    const setLow = (measure) => {
        if (lowest === 0) {
            lowest = (measure < 0) ? 0 : measure;
        }
        else if (measure < lowest) {
            lowest = (measure < 0) ? 0 : measure;
        }
    }

    React.useEffect(() => {
        let mounted = true;
        async function load() {
            if (mounted) {
                await loop();
            }
        }
        load();
        return () => mounted = false;
    }, [])

    return (
        <Flex>
            {/* Desktop */}
            <Flex
                display={['none', 'none', 'flex','flex']}
            >
                <Popover>
                    <PopoverTrigger>
                        <Button
                            leftIcon={<ChevronDownIcon />}
                            mt={0}
                            color="current"
                            variant="ghost"
                            type="submit"
                            onClick={ () => setSelected(!isSelected) }
                        >
                            Latency
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent mr={3}>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader>Closest node: ~{ latency }ms away</PopoverHeader>
                        <PopoverBody>Node location: { server }</PopoverBody>
                    </PopoverContent>
                </Popover>
            </Flex>
            {/* Mobile */}
            <Flex
                display={['flex', 'flex', 'none','none']}
            >
                <Popover>
                    <PopoverTrigger>
                        <IconButton
                            size="md"
                            fontSize="lg"
                            variant="ghost"
                            color="current"
                            marginLeft="2"
                            icon={<ChevronDownIcon />}
                        />
                    </PopoverTrigger>
                    <PopoverContent mr={3}>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader>Closest node: ~{ latency }ms away</PopoverHeader>
                        <PopoverBody>Node location: { server }</PopoverBody>
                    </PopoverContent>
                </Popover>
            </Flex>
        </Flex>
    )
}

export default LatencyMonitor;