import React, { useState } from 'react';
import {
  ChakraProvider,
  Box,
  Grid,
  theme,
} from '@chakra-ui/react';
import Blocklist from './components/Blocklist.js';
import UrlForm from './components/UrlForm';
import Header from './components/Header';

const apiUrl = "https://blitz.ahadns.com/blocklists";

function App() {
  const [blocklists, setBlocklists] = useState([]);
  const [lists, setlists] = useState([]);

  const onSelect = (id, value) => {
    let selectedLists = lists;

    if (value && selectedLists.indexOf(id) === -1) {
      console.log("pushing: ", id);
      selectedLists.push(id);
    }
    else if(!value) {
      console.log("removing: ", id);
      selectedLists = selectedLists.filter(function (e) {
        return e !== id;
      });
    }
    selectedLists = selectedLists.sort((a, b) => a - b); // Sort in ascending order
    selectedLists = Array.from(selectedLists, i => i);
    setlists(selectedLists);
  }

  const getBlocklists = async () => {
    try {
      const data = await fetch(apiUrl, { mode: 'cors' });
      return await data.json();
    }
    catch (e) {
      console.error(e)
    }
  }

  React.useEffect(() => {
    let mounted = true;
    async function load() {
      var blocklists = await getBlocklists();
      if (mounted) {
        console.log("Got blocklists:", blocklists)
        setBlocklists(blocklists);
      }
    }
    load();
    document.title = "AhaDNS Blitz Setup";
    return () => mounted = false;
  }, [])

  return (
    <ChakraProvider theme={theme}>
    <Box textAlign="center" fontSize="xl">
      <Grid p={3}>
        <Header/>
        <UrlForm selectedLists={lists} />
      </Grid>
      <Grid p={3}>
        <ul>
          {blocklists.map((list) => (<Blocklist onSelect={onSelect} data={list} key={list.id} />))}
        </ul>
      </Grid>
      
    </Box>
    </ChakraProvider>
  );
}

export default App;
