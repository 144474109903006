import React from 'react';
import {
    HStack,
    Spacer,
    Image,
    Grid,
    Button,
    Divider,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from "@chakra-ui/react";
import { HamburgerIcon } from '@chakra-ui/icons';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import LatencyMonitor from './LatencyMonitor';


const Header = () => {
    return (
        <Grid>
            {/* Desktop */}
            <Grid p={1}
                display={['none', 'none', 'grid','grid']}
            >
                <HStack align="center">
                    <Image
                        boxSize="50px"
                        objectFit="cover"
                        src="https://ahadns.com/wp-content/uploads/2020/12/pi-dns-icon-transparent.png"
                        alt="Logo"
                    />
                    <h1>AhaDNS Blitz</h1>
                    <Spacer />
                    <Divider orientation="vertical" />
                    <Button
                        mt={0}
                        color="current"
                        variant="ghost"
                        type="submit"
                        onClick={() => window.open("https://ahadns.com/", "_self")}>
                        Home
                    </Button>
                    <Divider orientation="vertical" />
                    <Button
                        mt={0}
                        color="current"
                        variant="ghost"
                        type="submit"
                        onClick={() => window.open("https://ahadns.com/setup-guides/", "_self")}>
                        Setup guides
                    </Button>
                    <Divider orientation="vertical" />
                    <Button
                        mt={0}
                        color="current"
                        variant="ghost"
                        type="submit"
                        onClick={() => window.open("https://ahadns.com/blitz/", "_self")}>
                        What is Blitz?
                    </Button>
                    <Divider orientation="vertical" />
                    <Spacer/>
                    <LatencyMonitor justifySelf="flex-end"/>
                    <ColorModeSwitcher justifySelf="flex-end" />
                </HStack>
            </Grid>
            {/* Mobile */}
            <Grid p={1}
                display={['grid', 'grid', 'none','none']}
            >
                <HStack align="center">
                    <Image
                        boxSize="50px"
                        objectFit="cover"
                        src="https://ahadns.com/wp-content/uploads/2020/12/pi-dns-icon-transparent.png"
                        alt="Logo"
                    />
                    <Spacer />
                    <Menu>
                        <MenuButton
                            as={Button}
                            rightIcon={<HamburgerIcon />}
                            variant="ghost"
                            type="submit">
                            Menu
                        </MenuButton>
                        <MenuList>
                            <MenuItem
                                onClick={() => window.open("https://ahadns.com/", "_self")}>
                                Home
                            </MenuItem>
                            <MenuItem
                                onClick={() => window.open("https://ahadns.com/setup-guides/", "_self")}>
                                Setup guides
                            </MenuItem>
                            <MenuItem
                                onClick={() => window.open("https://ahadns.com/blitz/", "_self")}>
                                What is Blitz?
                            </MenuItem>
                        </MenuList>
                    </Menu>
                    <LatencyMonitor justifySelf="flex-end"/>
                    <ColorModeSwitcher justifySelf="flex-end" />
                </HStack>
            </Grid>
        </Grid>
    )
}

export default Header;