import React, { useState } from 'react';
import {
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    Button,
    useClipboard,
    Stack,
    Spacer,
    Divider,
    Grid
} from "@chakra-ui/react";
import { CopyIcon, DownloadIcon } from '@chakra-ui/icons';

const UrlForm = ({ selectedLists }) => {
    const baseUrl = "https://blitz.ahadns.com";
    const mobileConfBaseUrl = "https://blitz.ahadns.com/config/mobileconfig";
    const [url, setUrl] = useState(baseUrl);
    const { hasCopied, onCopy } = useClipboard(url);

    const format = (selectedLists) => {
        let s = "";
        selectedLists.forEach(e => {
            s += e + ".";
        });
        return s;
    }
    const trimEndOfString = (s) => {
        let lastChar = s.slice(-1);
        if (lastChar === "/") {
            s = s.slice(0, -1);
        }
        if (lastChar === "-") {
            s = s.slice(0, -1);
        }
        if (lastChar === ".") {
            s = s.slice(0, -1);
        }
        return s;
    }
    const path = (lists) => {
        let path = (lists.length > 0) ? "1:" + format(lists) : "";
        return trimEndOfString(path);
    }
    const createUrl = (lists) => {
        let url = baseUrl + "/" + path(lists);
        return trimEndOfString(url);
    }
    function handleInputChange(e) {
        if (e?.value != null) {
            setUrl(e.value);
        }
    }
    
    return (
        <FormControl id="url" isReadOnly="true" mt={4}>
            <FormLabel></FormLabel>
            <Input
                type="text"
                ref={(input) => handleInputChange(input)}
                value={createUrl(selectedLists)}
                fontSize={["medium","large","large","large"]}
                height="50px"
            />
            <FormHelperText></FormHelperText>
            <Grid p={0} mb={3} >
                <Stack
                    direction={["column", "column", "row", "row"]}
                    mt={[1, 1, 2, 2]}
                    mb={2}
                    spacing={2}
                    align="center">
                    <Spacer />
                    <Divider orientation="vertical" display={["none", "none", "divider", "divider"]} />
                    <Button
                        mt={0}
                        width={["100%", "100%", "50%", "30%"]}
                        leftIcon={<CopyIcon />}
                        color="current"
                        variant="ghost"
                        borderRadius="2"
                        type="submit"
                        size="lg"
                        onClick={onCopy}
                        >
                        {hasCopied ? "Copied" : "Copy URL"}
                    </Button>
                    <Divider orientation="vertical" display={["none", "none", "divider", "divider"]} />
                    <Divider orientation="horizontal" display={["divider", "divider", "none", "none"]} />
                    <Button
                        mt={0}
                        width={["100%", "100%", "50%", "30%"]}
                        leftIcon={<DownloadIcon />}
                        color="current"
                        variant="ghost"
                        borderRadius="2"
                        type="submit"
                        size="lg"
                        onClick={() => window.open(`${mobileConfBaseUrl}?path=${ path(selectedLists)}`, "_blank")}>
                        Apple mobileconfig
                    </Button>
                    <Divider orientation="vertical" display={["none", "none", "divider", "divider"]} />
                    <Spacer/>
                </Stack>
            </Grid>
    </FormControl>
    )
}

export default UrlForm;